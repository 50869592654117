
import React, { useState } from 'react';
import axios from 'axios';
import './quiz.css';

const questions = [
  {
    questionText: 'What is your hair type?',
    answerOptions: [
      { answerText: 'Straight', value: 'straight' },
      { answerText: 'Wavy', value: 'wavy' },
      { answerText: 'Curly', value: 'curly' },
      { answerText: 'Coily', value: 'coily' },
    ],
  },
  {
    questionText: 'What is your hair porosity?',
    answerOptions: [
      { answerText: 'Low', value: 'low' },
      { answerText: 'Medium', value: 'medium' },
      { answerText: 'High', value: 'high' },
    ],
  },
  {
    questionText: 'What hair problem are you trying to solve?',
    answerOptions: [
      { answerText: 'Dandruff', value: 'dandruff' },
      { answerText: 'Damaged Hair', value: 'damaged' },
      { answerText: 'Colored Hair', value: 'colored' },
      { answerText: 'Dry Hair', value: 'dry' },
      { answerText: 'Oily Hair', value: 'oily' },
    ],
  },
];

const Quiz = () => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState({ hairType: '', porosity: '', problem: '' });
  const [showRecommendations, setShowRecommendations] = useState(false);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleAnswerOptionClick = (value) => {
    const newAnswers = { ...answers };
    if (currentQuestion === 0) newAnswers.hairType = value;
    if (currentQuestion === 1) newAnswers.porosity = value;
    if (currentQuestion === 2) newAnswers.problem = value;
    setAnswers(newAnswers);

    if (currentQuestion + 1 < questions.length) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      fetchRecommendations(newAnswers);
    }
  };

  const fetchRecommendations = async (answers) => {
    setLoading(true);
    const query = `${answers.hairType} ${answers.porosity} ${answers.problem} hair product`;
    const options = {
      method: 'GET',
      url: 'https://sephora.p.rapidapi.com/us/products/v2/search',
      params: { q: query, pageSize: '20', currentPage: '1' },
      headers: {
        'X-RapidAPI-Key': 'a07511bc09mshccab52473eda565p1c7b1ejsn9463be4c2820',
        'X-RapidAPI-Host': 'sephora.p.rapidapi.com'
      },
    };

    try {
      const response = await axios.request(options);
      console.log(response.data.products);
      if (response.data && response.data.products) {
        setProducts(response.data.products);
      } else {
        setProducts([]);
      }
      setShowRecommendations(true);
    } catch (error) {
      console.error("Failed to fetch products:", error);
      setProducts([]);
      setShowRecommendations(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="quiz-container">
      {loading ? (
        <div className="loading">Loading...</div>
      ) : showRecommendations ? (
        <div>
          <button onClick={() => setShowRecommendations(false)} className="back-button">Back to Quiz</button>
          <h2>Recommended Products for you</h2>
          <div className="product-grid">
            {products.map((product) => (
              <div key={product.productId} className="product-card">
                <img src={product.image450} alt={product.displayName} />
                <p>{product.displayName}</p>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div>
          <h2 className="question-text">{questions[currentQuestion].questionText}</h2>
          <div className="answer-section">
            {questions[currentQuestion].answerOptions.map((option, index) => (
              <button key={index} onClick={() => handleAnswerOptionClick(option.value)}>
                {option.answerText}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Quiz;
